import { render, staticRenderFns } from "./canceleddetail.vue?vue&type=template&id=77b2d3d8&scoped=true"
import script from "./canceleddetail.vue?vue&type=script&lang=js"
export * from "./canceleddetail.vue?vue&type=script&lang=js"
import style0 from "./canceleddetail.vue?vue&type=style&index=0&id=77b2d3d8&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77b2d3d8",
  null
  
)

export default component.exports